@import '~syngenta-digital-cropwise-react-ui-kit/lib/styles/index.less';
@import 'syt.antd.less';

.@{ant-prefix}-picker {
  width: 100%;
}

.@{ant-prefix}-picker-dropdown {
  @media screen and (max-width: @screen-sm) {
    top: 68px !important;
    height: calc((var(--vh, 1vh) * 100) - 68px);
    background-color: fade(@cws-neutral-90, 50%) !important;
  }
}

.@{ant-prefix}-picker-panel-container {
  @media screen and (max-width: @screen-sm) {
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

.@{ant-prefix}-picker-date-panel,
.@{ant-prefix}-picker-year-panel,
.@{ant-prefix}-picker-dropdown,
.@{ant-prefix}-picker-header,
.@{ant-prefix}-picker-footer,
.@{ant-prefix}-picker-body {
  @media screen and (max-width: @screen-sm) {
    width: 100vw !important;
  }
}

.@{ant-prefix}-picker-header {
  border-bottom: 1px solid @cws-neutral-20 !important;
}

.@{ant-prefix}-picker-footer {
  border-top: 1px solid @cws-neutral-20 !important;
  @media screen and (max-width: @screen-sm) {
    margin-bottom: 15px;
  }
}

.@{ant-prefix}-picker-decade-panel,
.@{ant-prefix}-picker-year-panel,
.@{ant-prefix}-picker-quarter-panel,
.@{ant-prefix}-picker-month-panel,
.@{ant-prefix}-picker-week-panel,
.@{ant-prefix}-picker-date-panel,
.@{ant-prefix}-picker-time-panel {
  width: 327px !important;
}

.@{ant-prefix}-picker-decade-panel .syt-antd-picker-body .syt-antd-picker-cell-inner {
  width: auto !important;
  padding: 6px 4px !important;
}

.@{ant-prefix}-picker-content > thead > tr > th {
  font-size: 14px;
  font-weight: 600;
  color: @cws-neutral-60;
}

.@{ant-prefix}-picker-cell-today:before {
  content: '';
  position: absolute;
  left: 10px !important;
  bottom: 0;
  width: 16px;
  padding-bottom: 12px;
  border-bottom: 2px solid @cws-blue-60;
}

.@{ant-prefix}-picker-cell-today .@{ant-prefix}-picker-cell-inner {
  text-decoration: none !important;
}

.@{ant-prefix}-picker-cell:not(.@{ant-prefix}-picker-cell-in-view) {
  padding: 4px 0;
}

.@{ant-prefix}-picker-header-view,
.@{ant-prefix}-picker-footer a,
.@{ant-prefix}-picker-cell-inner {
  font-size: 14px;
  font-weight: 400;
}

.@{ant-prefix}-picker-header-super-prev-btn,
.@{ant-prefix}-picker-header-super-next-btn,
.@{ant-prefix}-picker-header-prev-btn,
.@{ant-prefix}-picker-heder-next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.@{ant-prefix}-picker-panel-container .@{ant-prefix}-picker-panel .@{ant-prefix}-picker-content,
.@{ant-prefix}-picker-panel-container .@{ant-prefix}-picker-panel table .ant-picker-suffix {
  margin: 0 auto;
}
