@import '~syngenta-digital-cropwise-react-ui-kit/lib/styles/index.less';
@import 'syt.antd.less';

@mapbox-default-compact-icon-screen: 640px;
.move-marker {
  width: 42px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/movePin.png);
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: @cws-neutral-30;
  border: none;
}

.mapboxgl-ctrl-geocoder {
  width: 343px !important;
  height: 40px !important;
  border-radius: 4px;
  font-family: 'Noto Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: @cws-neutral-60;
  background-color: @cws-neutral-90;

  @media screen and (max-width: @screen-lg) {
    min-width: unset;
    width: calc(100% - 31px) !important;
  }
  @media screen and (max-width: @screen-sm) and (max-width: @screen-md) {
    border-color: #232630;
  }
}

.mapboxgl-ctrl-geocoder--icon {
  top: 10px !important;
}

.mapboxgl-ctrl-geocoder--input {
  height: 40px !important;
}

.mapboxgl-ctrl-geocoder--input::placeholder {
  color: #fff;
}

.mapboxgl-ctrl-geocoder--icon-search {
  @media screen and (max-width: @screen-sm) and (max-width: @screen-md) {
    fill: '#A3A9B9';
  }
}

.mapboxgl-ctrl-geocoder .suggestions {
  top: calc(100% + 1px) !important;

  .mapboxgl-ctrl-geocoder--suggestion {
    font-size: 14px;
    font-weight: 400;
    color: @cws-neutral-100;
  }

  .mapboxgl-ctrl-geocoder--suggestion-title {
    font-weight: 400;
  }
}

.mapboxgl-ctrl-geocoder--icon-close {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--button {
  top: 10px !important;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  width: 100%;
  @media screen and (max-width: @screen-md) {
    z-index: 3;
  }

  .mapboxgl-ctrl {
    @media screen and (max-width: @screen-md) {
      width: 100%;
      margin: 0;
    }
  }
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin: 8px 0 10px 8px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 8px 8px 0 0;
}

.mapboxgl-ctrl-bottom-left {
  padding-right: 16px;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  background-color: @cws-blue-10;

  .mapboxgl-ctrl-geocoder--suggestion-title,
  .mapboxgl-ctrl-geocoder--suggestion-address {
    font-weight: 600;
  }
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a {
  @media screen and (max-width: @screen-md) {
    background-color: unset;
  }

  .mapboxgl-ctrl-geocoder--suggestion-title,
  .mapboxgl-ctrl-geocoder--suggestion-address {
    @media screen and (max-width: @screen-md) {
      font-weight: 400;
    }
  }
}

.mapbox-gl-geocoder--no-results {
  @media screen and (max-width: @screen-md) {
    text-align: center;
    padding: 7px 0;
  }
}

.geocoder-disabled input {
  pointer-events: none;
  background: @cws-neutral-30;
  border: 1px solid @cws-neutral-90;
  border-radius: 4px;
}

.geocoder-disabled {
  cursor: not-allowed;
}

.geocoder-disabled button {
  background-color: @cws-neutral-30;
  cursor: not-allowed;
}

.animate-drop-pin {
  animation: dropPinAnimation 1s ease-in 0s 1 normal forwards;
}

.mapboxgl-ctrl-bottom-right {
  margin-bottom: 10px;

  @media screen and (max-width: 748px) {
    margin-bottom: 67px;
  }

  @media screen and (max-width: @mapbox-default-compact-icon-screen) {
    margin-bottom: 93px;
  }
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  @media screen and (max-width: @mapbox-default-compact-icon-screen) {
    position: fixed;
    top: 72px;
    right: 0;
  }
}

a {
  font-weight: 600;
}

#map-container {
  .@{ant-prefix}-notification {
    position: absolute;
    z-index: 1 !important;
    transform: none !important;
    pointer-events: none;

    .@{ant-prefix}-notification-notice {
      margin: auto;
      width: 100%;
      pointer-events: auto;
    }

    .@{ant-prefix}-notification-notice-close {
      right: 6px !important;
      background-color: white;
    }
  }

  .@{ant-prefix}-notification-top {
    inset: 16px 20px auto 60px !important;

    @media screen and (max-width: 900px) {
      inset: 16px 20px auto 20px !important;
      margin-top: 38px;
    }
  }

  .@{ant-prefix}-notification-bottom {
    inset: auto 20px 50px 60px !important;
  }

  &.draw-flow {
    .@{ant-prefix}-notification-top {
      inset: 16px 20px auto 20px !important;

      @media screen and (max-width: 1215px) {
        inset: 16px 20px auto 190px !important;
      }

      @media screen and (max-width: 760px) {
        inset: 16px 20px auto 20px !important;
      }
    }
  }
}

#stepsControl {
  display: none;

  @media screen and (max-width: 748px) {
    display: flex;
  }
}

@keyframes dropPinAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}

#flowMap:hover #tooltip {
  opacity: 1;
}
