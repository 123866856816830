@import '~syngenta-digital-cropwise-react-ui-kit/lib/styles/index.less';
@import 'syt.antd.less';
html,
body,
#root {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.toast-notification {
  padding: 10px 16px 13px 16px !important;
  margin-top: 18px !important;

  &.@{ant-prefix}-info-notification {
    border: none !important;
  }

  .@{ant-prefix}-notification-notice {
    &-description {
      margin-left: 33px !important;
      font-size: 14px !important;
    }
  }

  &.rotate-notification {
    z-index: 1 !important;
    margin-top: 52px !important;
    width: 265px !important;

    @media screen and (orientation: landscape) {
      display: none;
    }
  }
}

.toast-notification-without-icon {
  .toast-notification();
  .@{ant-prefix}-notification-notice-description {
    margin-left: 0 !important;
  }
}

.syt-antd-input-wrapper-danger {
  input {
    margin: 2px 0px;
  }
}

.syt-antd-btn > .anticon {
  line-height: 0;
}

.ant-typography-danger {
  color: @cws-red-50;
}

.delete-boundary-modal {
  box-shadow: 0px -4px 0px #eb4b4b;
  border-radius: 4px 4px 0px 0px;
  background-color: white;
}

.error-border {
  border-color: @cws-red-50 !important;
}

.@{ant-prefix}-progress-stepper-prev-btn {
  background: @cws-neutral-00 !important;
  color: @cws-neutral-100 !important;
  border: 1px solid @cws-neutral-30 !important;
  &:disabled {
    background-color: @cws-neutral-10 !important;
    color: @cws-neutral-30 !important;
    border-color: @cws-neutral-20 !important;
  }
  &:enabled {
    &:focus {
      color: @cws-neutral-100 !important;
    }
    &:hover {
      background: @cws-neutral-10 !important;
      color: @cws-neutral-100 !important;
    }
    &:active {
      background: @cws-neutral-20 !important;
      color: @cws-neutral-100 !important;
    }
  }
}
.@{ant-prefix}-progress-stepper-next-btn {
  &:disabled {
    background-color: @cws-neutral-10 !important;
    color: @cws-neutral-30 !important;
    border-color: @cws-neutral-20 !important;
  }
  &:enabled {
    &:hover {
      background: @cws-green-50 !important;
    }
    &:active {
      background: @cws-green-70 !important;
    }
  }
}

.showOnLandscape {
  @media (orientation: landscape) {
    display: flex !important;
  }
  @media (orientation: portrait) {
    display: none !important;
  }
}
.hideOnLandscape {
  @media (orientation: landscape) {
    display: none !important;
  }
  @media (orientation: portrait) {
    display: flex !important;

    .block {
      display: block !important;
    }
  }
}

.hide {
  display: none !important;
}

#goBackHomeModal {
  z-index: 1999;
}

.anticon.anticon-close-circle svg {
  width: 15px;
  height: 15px;
}

.syt-antd-notification.syt-antd-notification-bottom {
  margin-bottom: 0px;
  inset: auto auto 85px 50% !important;
  transform: translate(-50%);
}

#ServiceUnavailableModal {
  z-index: 9999 !important;
}

.slick-dots-bottom {
  z-index: 1 !important;
}

.toast-notification-with-icon {
  .syt-antd-notification-notice-description {
    margin-left: 40px !important;
    .view-info-link {
      margin-left: 10px;
    }
  }
  .syt-antd-notification-notice-icon {
    font-size: 20px;
  }
}

.syt-antd-collapse-arrow {
  width: 12px;
  height: 7.41px;
}

.toast-notification-env-info-mobile { 
    border-radius: 4px;
    border-left: 4px solid #0092e4;
}

.toast-notification-draw {
  padding: 12px 16px !important;
  .syt-antd-notification-notice-message { 
    display: none;
  }
}

.toast-notification-select {
  padding: 12px 16px !important;
  .syt-antd-notification-notice-message { 
    display: none;
  }
  .syt-antd-notification-notice-description { 
    margin-left: 0 !important;
  }
}

.drop-pin-help-popup {
  .syt-antd-notification-notice-icon-info {
    font-size: 20px;
  }
}

.syt-antd-checkbox-input:focus + .syt-antd-checkbox-inner, .syt-antd-slider-handle:focus {
  box-shadow: none !important;
}
.anticon.syt-antd-notification-notice-icon-success {
  color: #14803C;
}

// confirmation page; phone number formatting
.phone-text-only {
  position: initial;
  width: auto;
  display: inline;
  .react-tel-input .form-control {
    padding-left: 0;
    width: auto;
  }
  .flag-dropdown, .special-label {
    display: none;
  }
  input {
    background: none !important;
    border: none !important;
    margin: 0;
    padding: 0;
    padding-left: 0 !important;
    width: 120px !important;
  }
}

.syt-antd-tooltip.lower-z-index {
  z-index: 99 !important;
}

.text-link {
  color: #FFF;
  font-weight: normal;
  max-width: auto !important;
}
