.weather-combined-chart {
  overflow-x: auto;
  overflow-y: hidden;
  top: 0;
  left: 0;
  .c3-region.regionHT {
    fill: red !important;
  }
  .c3-xgrid-lines {
    .c3-xgrid-line {
      line {
        stroke-dasharray: 2,2;
      }
    }
  }
}

.c3-axis-y-label {
  font-size: 16px;
  fill: rgb(207, 53, 55);
}

.c3-axis-y2-label {
  font-size: 16px;
  fill: rgb(0, 113, 205);
}

.c3-tooltip-container {
  background-color: #141414;
  color: aliceblue;
  border-radius: 5px;
  padding: 10px;
  div div {
    line-height: 19px;
  }
}

.legend span {
  width: 33.333333%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: white;
}

.c3-shapes-Growing-Degree-Days path {
  opacity: 0.7;
  stroke-width: 6px;
}
