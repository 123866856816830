/* chart */
.shape {
  fill-opacity: 0.1;
}

.shape:hover {
  fill-opacity: 0.3;
  z-index: 100;
}

.scale {
  stroke: #c2c7d0;
  stroke-width: 1;
}

.axis {
  stroke: #c2c7d0;
  stroke-width: 1;
}

.dot {
  fill: white;
  stroke: #e7e8e7;
  r: 5;
  transition: r 0.2s;
}

.dot:hover {
  stroke: #bbb;
  r: 8;
}

.caption {
  fill: #4d5165;
  font-weight: 600;
}
